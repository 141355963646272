const colors = {
  base: {
    '00': '#FFFFFF',
    50: '#F0F2F5',
    100: '#E9ECF0',
    200: '#D9DEE5',
    300: '#BDC5D2',
    400: '#9EAABC',
    500: '#8695AB',
    600: '#4061B6',
    700: '#385195',
    800: '#314577',
    900: '#1B233A',
    950: '#13192A',
  },
  category: {
    '/': '#F0F2F5',
    esportes: '#50b2cd',
    esports: '#8f6fae',
    pop: '#8F2E8A',
    dados: '#4061B6',
    tv: '#BB3838',
    promos: '#0EAA43',
    lojas: '#62BA97',
    games: '#902d88',
    cinema: '#12a942',
    series: '#63ba98',
    anime: '#8f6fae',
  },
  actions: {
    live: '#E83D30',
  },
  logo: {
    betnacional: '#1E40AF',
  },
  match: {
    goal: '#EBF2ED',
    yellowCard: '#F1F2EB',
    redCard: '#F2EBEB',
    homeTeam: '#62BA97',
    awayTeam: '#8F6EAD',
  },
};

export default colors;
